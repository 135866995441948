import React from "react"
import Image from "next/image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import dynamic from "next/dynamic"

import { uid } from "uid"
import { useGlobalContext } from "../src/context/layout-context"

import SEOHead from "../src/components/layout/head"
import SchemaImage from "../src/components/schema/schema-image"

import { getData } from "../graphql/error-page"

const FindLocationBar = dynamic(() =>
  import("../src/components/location/find-location-bar")
)
const BannerBlockCTA = dynamic(
  () => import("../src/components/blocks/banner-block-cta"),
  { ssr: false }
)
const ButtonTriangle = dynamic(
  () => import("../src/components/buttons/button-triangle"),
  { ssr: false }
)

function ErrorPage({ page }) {
  const { globalState } = useGlobalContext()
  const { setCenter, setLat, setLng, isMobile } = globalState || {}

  return (
    <>
      <SEOHead
        title={
          page.items[0].seoMetadata ? page.items[0].seoMetadata.seoTitle : null
        }
        description={
          page.items[0].seoMetadata
            ? page.items[0].seoMetadata.description
            : null
        }
        keywords={
          page.items[0].seoMetadata ? page.items[0].seoMetadata.keywords : null
        }
        image={
          page.items[0].seoMetadata ? page.items[0].seoMetadata.image : null
        }
        noindex={page.items[0].seoMetadata ? page.items[0].seoMetadata.noindex : null}
        nofollow={page.items[0].seoMetadata ? page.items[0].seoMetadata.nofollow : null}
      />

      <React.StrictMode>
        <section>
          {page.items[0].blocks.items && (
            <>
              {page.items[0].blocks.items.map(block => (
                <React.Fragment key={uid(16)}>
                  {block.blockType === "ImageTextBlock" && (
                    <> 
                      {block.cover || block.cover.mobileImage ? (
                        <SchemaImage image={block.cover || block.cover.mobileImage}/>
                      ) : null}

                      <section className="relative">
                        <div className="relative md:static pt-10 pb-30 md:pb-0">
                          {isMobile ? (
                            <Image
                              src={block.mobileCover.url}
                              alt="background"
                              layout="fill"
                              className="object-cover object-center absolute inset-0"
                              priority="true"
                              loading="eager"
                              placeholder="blur"
                              blurDataURL="data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkWL/qPwAEagJa4rsAIAAAAABJRU5ErkJggg=="
                            />
                          ) : (
                            <Image
                              src={block.cover.url}
                              alt="background"
                              layout="fill"
                              className="object-cover object-center absolute inset-0"
                              priority="true"
                              loading="eager"
                              placeholder="blur"
                              blurDataURL="data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkWL/qPwAEagJa4rsAIAAAAABJRU5ErkJggg=="
                            />
                          )}
                          <div className="bg-black absolute left-0 top-0 w-full h-full opacity-50">
                            &nbsp
                          </div>
                          <div className="relative z-1 text-white flex flex-col items-center justify-items-center justify-center sm:h-450px lg:h-750px px-2">
                            <hr className="bg-white transform -rotate-6 h-1 w-24" />
                            <h1 className="my-5 max-w-410px text-center">
                              {block.header}
                            </h1>
                            <hr className="bg-white transform -rotate-6 h-1 w-24" />
                            <div className="text-lg font-extrabold my-6 max-w-600px text-center">
                              {documentToReactComponents(block.content.json)}
                            </div>
                            <ButtonTriangle
                              triangle
                              placeholder={block.cta.items[0].label}
                              href={`${block.cta.items[0].externalUrl}/`}
                              classList="btn-yellow text-black"
                            />
                          </div>
                        </div>
                        <FindLocationBar
                          classList="bg-transparent text-white -mt-40 md:-mt-0"
                          setCenter={setCenter}
                          setLat={setLat}
                          setLng={setLng}
                        />
                      </section>
                    </>
                  )}
                  {/* Content Block 5, CTA-1, CTA-2, CTA-3 */}
                  {block &&
                    block.blockType === "TextCta" &&
                    block.type === "Normal" && (
                      <BannerBlockCTA
                        title={block.header}
                        content={block.intro}
                        buttons={block.cta.total > 0 ? block.cta.items : null}
                        titleTag="h4"
                        titleClassList="font-nandos text-9xl mb-0"
                        lineWidth="w-90px"
                        classList="py-15"
                        backgroundColor={block.backgroundColor}
                        titleAlign="Center"
                        textContentAlign="Center"
                      />
                    )}
                </React.Fragment>
              ))}
            </>
          )}
        </section>
      </React.StrictMode>
    </>
  )
}

export const getStaticProps = async () => {
  let previewVar = false
  if (process.env.NEXT_PUBLIC_CF_PREVIEW) {
    previewVar = process.env.NEXT_PUBLIC_CF_PREVIEW
  }
  const page =
    (await getData({
      preview: previewVar,
      locale: `en-${process.env.NEXT_PUBLIC_COUNTRY.toUpperCase()}`,
    })) ?? []
  return {
    props: {
      page: page.data.page,
    },
  }
}

export default ErrorPage
